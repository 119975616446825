<template>
	<div>
		<div v-if="form">
			<div>
				<p>Status</p>
				<select v-model="form.data.slot.fields.status.value">
					<option>active</option>
					<option>pending_shuffle</option>
					<option>pending_review</option>
					<option>expired</option>
					<option>shuffling_in</option>
					<option>expiring</option>
				</select>
			</div>
			<br>
			<div v-if="in_slot">
				<p><b>Should be replaced by</b></p>
				<a @click="showInSlotInfo()" class="button is-info">{{ in_slot.position.title }}</a>
				<div style="margin-top: 10px;">
					<a @click="retryShuffle()" class="button is-info" :class="{ 'is-loading': is_retrying_shuffle }">Retry Shuffle</a>
					<a @click="cancelShuffle()" class="button is-danger" style="margin-right: 10px;" :class="{ 'is-loading': is_cancelling_shuffle }">
						Cancel Shuffle
					</a>
				</div>
			</div>
		</div>
		<div class="modal_footer">
			<a
				@click="submit()"
				:class="{ 'is-loading': is_loading }"
				class="button is-info"
				>Submit</a
			>
		</div>
	</div>
</template>
<script>
	import { mapMutations } from 'vuex'
	export default {
		name: 'slot-form',
		props: ['payload'],
		data(){
			return {
				is_retrying_shuffle: false,
				is_cancelling_shuffle: false,
				is_loading: false,
				in_slot: undefined,
				form: undefined
			}
		},
		created(){
			this.$network_utils.getSlot(this.payload.data.id).then(response => {
				this.setForm(response)
				this.getInSlot()
			})
		},
		methods: {
			setForm(form_data){
				this.form = new this.$form({
					slot: {
					  fields: {
					    id: {
					      value: form_data.id,
					      has_error: false,
					      validations: []
					    },
					    status: {
					    	value: form_data.status,
					    	has_error: false,
					    	validations: []
					    },
					  },
					  request_details: {
					    method: this.payload.is_create ? 'post' : 'put',
					    url: '/slots/' + form_data.id,
					    service: 'shuffle'
					  },
					  is_used: true
					}
				})		
			},
			getInSlot(){
				if(!this.payload.is_retryable){
					return
				}
				let supplier = this.payload.data.supplier
				if(supplier == undefined) {
					return
				}
				this.$network_utils.getSlots({ 
					supplier_id: supplier.id, 
					statuses: 'shuffling_in', 
					position_id: this.payload.data.replacement_id
				}).then(response => {
					response.sort((a, b) => {
						return b.id - a.id
					})
					this.in_slot = response[0]
				})
			},
			retryShuffle(){
				this.is_retrying_shuffle = true
				this.$network_utils.postEvent({ event: 'RetryReplacePositions', in_slot_id: this.in_slot.id, out_slot_id: this.payload.data.id }).then(() => {
					this.is_retrying_shuffle = false
					this.$utils.emit('show_toast', { text: 'Retried Successfuly' })
				})
			},
			cancelShuffle(){
				if(!confirm('בטוח')){
					return
				}
				this.is_cancelling_shuffle = true
				this.$network_utils.postEvent({ event: 'CancelShuffle', in_slot_id: this.in_slot.id, out_slot_id: this.payload.data.id }).then(() => {
					this.is_cancelling_shuffle = false
					this.$utils.emit('show_toast', { text: 'Cancelled Successfuly' })
				})
			},
			submit() {
				let response = this.form.mySubmit()
				if (response === false) {
					return
				}
				this.is_loading = true
				response.promise.then((response) => {
					this.is_loading = false
					this.removeModal(this.payload)
				})	
			},
			showInSlotInfo(){
				this.createModal({
					designator: 'slot-info-form-modal',
					component_name: 'SlotInfoForm',
					slot: this.in_slot,
					modal_title: `Slot - ${this.in_slot.position.title} - ${this.in_slot.position.position_applicant_code}`
				})
			},
			...mapMutations('modals', ['removeModal', 'createModal'])
		}
	}
</script>