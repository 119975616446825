export default {
  stylesheet: '/suitup.css',
  favicon: '/suitup_favicon.png',
  images: {
    logo_bright: '/suitup_logo_bright.png',
    logo_dark: '/suitup_logo_dark.png'
  },
  stargate_api_url: 'https://stargate.seasideapis.com/',
  shuffle_api_url: 'https://shuffle.seasideapis.com/',
}
