<template>
  <div id="app">
    <div v-if="show_toast" class="toast">
      <p>{{ toast_text }}</p>
    </div>
  	<modals></modals>
  	<custom-header v-if="$utils.isLoggedIn()"></custom-header>
    <router-view :key="$route.fullPath"/>
  </div>
</template>
<script>
	import { bus } from '@/classes/bus.js'
	import CustomHeader from '@/components/CustomHeader'
	import Modals from '@/components/Modals'
	export default {
		name: 'app',
		data(){
			return {
				show_toast: false,
				toast_text: ''
			}
		},
		created(){
		    if (!this.$utils.isLoggedIn()) {
		      this.$router.push({ name: 'login' })
		    }
			this.setRouter()
			this.listen()
		},
		watch: {
			$route(){
				this.setRouter()
			}
		},
		methods: {
			listen(){
				bus.$on('show_toast', data => {
					this.show_toast = true
					this.toast_text = data.text
					setTimeout(() => {
						this.show_toast = false
					}, 2100)
				})
			},
			setRouter(){
				this.$network_utils.setRouter(this.$route)
			},
		},
		components: {
			CustomHeader,
			Modals
		}
	}
</script>
<style>
	::-webkit-scrollbar {
	  width: 6px;
	  height: 6px;
	  margin-right: 30px;
	}
	::-webkit-scrollbar-track {
	  border-radius: 6px;
	}
	::-webkit-scrollbar-thumb {
	  background: var(--color-scrollbar);
	  border-radius: 6px;
	}
	:root {
	  --left-column-width: 220px;
	  --color-title: #333;
	  --color-header: #333;
	  --color-main: #333;
	  --color-secondary: #ffb52b;
	  --color-text: #333;
	  --color-button: #333;
	  --color-button-text: white;
	}
	html,body {
		background-color: #F2F2F2;
		font-family: "Poppins", sans-serif;
		padding: 0;
		margin: 0;
		margin-bottom: 30px;
	}
	.toast {
	  direction: rtl;
	  min-width: 190px;
	  border-radius: 30px;
	  background-color: #333;
	  color: #fff;
	  text-align: center;
	  padding: 6px 24px;
	  position: fixed;
	  z-index: 10000000000;
	  left: 50%;
	  transform: translateX(-50%);
	  bottom: 50%;
	  -webkit-animation: toastshow 0.3s, toasthide 0.3s 1.8s;
	  animation: toastshow 0.3s, toasthide 0.3s 1.8s;
	}
	@-webkit-keyframes toastshow {
	  from {
	    bottom: 0;
	    opacity: 0;
	  }
	  to {
	    bottom: 50%;
	    opacity: 1;
	  }
	}
	@keyframes toastshow {
	  from {
	    bottom: 0;
	    opacity: 0;
	  }
	  to {
	    bottom: 50%;
	    opacity: 1;
	  }
	}
	@-webkit-keyframes toasthide {
	  from {
	    bottom: 50%;
	    opacity: 1;
	  }
	  to {
	    bottom: 0;
	    opacity: 0;
	  }
	}
	@keyframes toasthide {
	  from {
	    bottom: 50%;
	    opacity: 1;
	  }
	  to {
	    bottom: 0;
	    opacity: 0;
	  }
	}
	.page-width{
		max-width: 1600px;
		margin: 0 auto;
	}
	.flex{
		display: flex;
	}
	.flex_wrap{
		flex-wrap: wrap;
	}
	.flex_direction_column{
		flex-direction: column;
	}
	.flex_justify_center{
		justify-content: center;
	}
	.flex_align_center{
		align-items: center;
	}
	.flex_justify_between {
	  justify-content: space-between !important;
	}
	.flex_align_start{
	  align-items: flex-start;
	}
	.flex_align_end{
	  align-items: flex-end;
	}
	.flex_align_self_bottom {
	  align-self: flex-end;
	}
	.flex_justify_end {
	  justify-content: flex-end;
	}
	.text_align_right{
		text-align: right;
	}
	.text_align_left{
		text-align: left;
	}
	.center_horizontal{
		left: 50%;
		transform: translateX(-50%);
	}
	.horizontal_space{
		width: 10px;
	}
	.vertical_space{
		height: 10px;
	}
	.absolute {
		position: absolute;
	}
	.relative {
		position: relative;
	}
	input {
		padding-left: 20px !important;
		border: none;
		border-radius: 30px;
		padding: 10px;
		font-size: 18px;
		color: var(--color-text);
	}
	textarea, .text_area {
		padding: 10px;
		border: 1px solid rgba(0, 0, 0, 0.1);
		resize: none;
		min-width: 420px;
		min-height: 180px;
	}
	textarea:focus,
	input:focus {
		outline: none;
	}
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	input[type="time"],
	input[type="date"] {
		background-color: #f2f2f2;
		color: var(--color-text);
	}
	input[type='text']{
		border: 1px solid #bdc4c9;
		padding: 5px 6px;
		padding-left: 6px !important;
		margin: 2px 0 5px;
		margin-bottom: 5px;
		box-sizing: border-box;
		-webkit-appearance: none;
		font-size: 14px;
		font-weight: 400;
		border-radius: 4px;
	}
	select {
		background: url("/black_down_arrow.svg") no-repeat right #ddd;
		-webkit-appearance: none;
		appearance: none;
		background-size: 10px;
		background-position-x: calc(100% - 10px);
		border-radius: 30px;
		border: 1px solid rgba(0, 0, 0, 0.1);
		background-color: #f1f1f1;
		min-width: 180px;
		padding: 10px;
	}
	select[dir="ltr"]{
		background-position-x: calc(100% - 10px);
	}
	select[dir="rtl"]{
		background-position-x: 10px;
	}
	th{
	  text-transform: uppercase;
	}
	.table {
	  margin-bottom: 0;
	}
	.table tr.odd {
	  background-color: #f2f2f2;
	}
	.table thead th {
	  border-top: 0;
	  border-bottom-width: 1px;
	  font-family: "Poppins", sans-serif;
	  font-weight: 700;
	}
	.table thead th i {
	  margin-left: 0.325rem;
	}
	.table td,
	.table th {
	  vertical-align: middle;
	  font-size: 0.875rem;
	  line-height: 1.3;
	}
	.table {
	  width: 100%;
	  max-width: 100%;
	  margin-bottom: 1rem;
	  background-color: transparent;
	}
	.table th,
	.table td {
	  padding: 1rem;
	}
	.table thead th {
	  text-align: left;
	  font-weight: 7 00;
	  vertical-align: bottom;
	}
	.table tbody + tbody {
	  border-top: 2px solid #f3f3f3;
	}
	.table .table {
	  background-color: #fff;
	}
	.outlined{
		border: 1px solid rgba(0, 0, 0, 0.1);
	}
	.full_width{
		width: 100%;
	}
	.full_height {
		height: 100%;
	}
	.margin_0{
		margin: 0 !important;
	}
	.padding_0{
		padding: 0 !important;
	}
	.bold {
		font-weight: bold;
	}
	.btn{
		cursor: pointer;
	}
	.modal_form{
	  padding-bottom: 30px;
	}
	.modal_footer {
		box-shadow: 0px 10px 30px -3px rgba(0, 0, 0, 0.75);
		direction: ltr;
		position: fixed;
		background-color: white;
		left: 0;
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
		bottom: -62px;
		display: flex;
		padding: 10px;
		margin: 0 !important;
		justify-content: flex-start;
		align-items: center;
		border-top: 1px solid var(--color-main);
		width: 100%;
		height: 70px;
	}
	.loading {
	  height: 30px;
	  width: 30px;
	}
	.loading:after {
	  -webkit-animation: spinAround 0.6s infinite linear;
	  animation: spinAround 0.6s infinite linear;
	  border: 2px solid var(--color-main);
	  border-radius: 290486px;
	  border-right-color: transparent;
	  border-top-color: transparent;
	  content: "";
	  display: block;
	  height: 30px;
	  width: 30px;
	}
</style>
