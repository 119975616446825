<template>
	<div>
		<p class="position_title">{{ payload.slot.position.title }}</p>
		<p><b>Position Applicant Code:</b> {{ payload.slot.position.position_applicant_code }}</p>
		<p><b>Slot Status:</b> {{ payload.slot.status }}</p>
	</div>
</template>
<script>
	export default {
		name: 'supplier-position-info',
		props: ['payload']
	}
</script>
<style scoped>
	.position_title{
		font-size: 25px;
		font-weight: bold;
		margin-bottom: 10px;
	}
</style>