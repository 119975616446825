import common from './settings/common/main'
import production from './settings/production/main'
import localhost from './settings/localhost/main'
import { getPropertyFromPath, getDomain } from './my_utils.js'

class Settings {
  constructor () {
    this.common = common
    this.production = production
    this.localhost = localhost
  }

  get (params) {
    let namespace = params.env == undefined ? this.getNamespace() : this.getEnv(params.env)
    let job_title = params.job_title == undefined ? 'no_job_title' : params.job_title
    try {
      return this.getPropertyFromPath({ context: this[namespace][job_title], path: params.path })
    } catch (error) {
      try {
        return this.getPropertyFromPath({ context: this[namespace]['common'], path: params.path })
      } catch (error) {
        try {
          return this.getPropertyFromPath({ context: this['common'], path: params.path })
        } catch (error) {
          return ''
        }
      }
    }
  }

  getEnv(env){
    if(env == 'Office'){
      return 'Suitup'
    }
    return env
  }

  getPropertyFromPath (params) {
    let response = getPropertyFromPath(params)
    if (response == undefined) {
      throw response
    }
    return response
  }

  getNamespace () {
    let domains = {
      'stargate.panel.rebase.co.il': 'production',
      'www.stargate.panel.rebase.co.il': 'production',
      'localhost': 'localhost'
    }
    let namespace = domains[getDomain()]
    return namespace || 'no_namespace'
  }
}

export default Settings
