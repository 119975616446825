<template>
	<div class="slot">
		<div v-if="is_loading" class="loading"></div>
		<a v-if="mutable_slot" @click="openSlot" class="button is-info" :class="{ 'is-danger': is_retryable }" v-html="getTitle()"></a>
	</div>
</template>
<script>
	import { mapMutations } from 'vuex'
	export default {
		name: 'supplier-form-slot-item',
		props: ['supplier_id', '_slot', 'position_applicant_code', 'is_retryable'],
		data(){
			return {
				is_loading: false,
				mutable_slot: this._slot
			}
		},
		created(){
			if(this.mutable_slot != undefined){
				return
			}
			this.getSlotFromPosition()
		},
		methods: {
			openSlot(){
				this.createModal({
					designator: 'slot-modal',
					component_name: 'SlotForm',
					data: this.mutable_slot,
					is_retryable: this.is_retryable,
					modal_title: `Slot - ${this.mutable_slot.supplier.username} - ${this.mutable_slot.position.title}`
				})
			},
			getTitle(){
				return `${this.$sanitize(this.mutable_slot.position.title)} (${this.mutable_slot.position.id})`
			},
			getSlotFromPosition(){
				this.is_loading = true
				this.$network_utils.getSlots({ supplier_id: this.supplier_id, position_applicant_code: this.position_applicant_code }).then(response => {
					this.mutable_slot = response[response.length - 1]
					this.is_loading = false
				})
			},
			...mapMutations('modals', ['createModal'])
		}
	}
</script>
<style scoped>
	.slot {
		margin: 3px;
	}
</style>