<template>
	<div id="header" class="page-width">
		<div class="flex flex_align_center">
			<a href="/" class="flex_align_end">
				<img src="/suitup_logo_bright.png" width="100px" class="space-between">
				<p class="tagline">Stargate</p>
			</a>
			<a class="button is-danger logout" @click="logout()">Logout</a>
		</div>
		<div class="links flex flex_align_center">
			<router-link :to="{ name: 'suppliers' }" :class="{ selected: $route.name == 'suppliers' }">Suppliers</router-link>
			<router-link :to="{ name: 'positions' }" :class="{ selected: $route.name == 'positions' }">Positions</router-link>
			<router-link :to="{ name: 'slots', query: { statuses: 'active' } }" :class="{ selected: $route.name == 'slots' }">Slots</router-link>
			<router-link :to="{ name: 'dashboard' }" :class="{ selected: $route.name == 'dashboard' }">Dashboard</router-link>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'custom-header',
		methods: {
			logout(){
				localStorage.clear()
				this.$router.push({ name: 'login' })
			}
		}
	}
</script>
<style scoped>
	#header {
		margin-top: 20px;
		border-radius: 6px;
		padding: 25px 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: var(--color-header);
		margin-bottom: 20px;
		box-shadow: 0 12px 18px 2px rgba(34,0,51,.04),0 6px 22px 4px rgba(7,48,114,.12),0 6px 10px -4px rgba(14,13,26,.12);
	}
	.links a {
		margin-left: 20px;
	}
	.selected{
		color: var(--color-secondary) !important;
	}
	a {
		color: white;
		text-decoration: none;
	}
	.links a:hover {
		text-decoration: underline;
	}
	.button:hover {
		text-decoration: none !important;
	}
	.logout {
		margin-left: 20px;
	}
	.tagline{
		margin-top: -7px;
		font-size: 10px; 
		margin-left: 1px;
	}
</style>