import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import CustomPrototypes from '@/classes/custom_prototypes.js'
import Settings from '@/classes/settings.js'
import MyUtils from '@/classes/my_utils.js'
import NetworkUtils from '@/classes/network_utils.js'
import Form from '@/classes/form.js'
import sanitizeHelper from '@/classes/sanitize_helper'

new CustomPrototypes().setPrototypes()
Vue.prototype.$settings = new Settings()
Vue.prototype.$network_utils = new NetworkUtils()
Vue.prototype.$utils = MyUtils
Vue.prototype.$form = Form
Vue.prototype.$sanitize = sanitizeHelper.sanitize
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
