<template>
	<div>
		<div>
			<p><b>SLOTS SIZE: </b> {{ payload.supplier.slots_size }}</p>
			<p><b>ACTIVE SLOTS: </b> {{ remote_slots.length }}</p>
		</div>
		<div dir="rtl" class="flex flex_justify_between text_align_right">
			<div class="server_slots">
				<div v-if="valid_slots.length > 0">
					<p class="bold">סלוטים שאמורים להיות אקטיביים מהשרת ואקטיביים בפועל: ({{ valid_slots.length }})</p>
					<div class="flex flex_wrap">
						<supplier-form-slot-item v-for="slot in valid_slots" :_slot="slot"></supplier-form-slot-item>
					</div>
				</div>
				<div class="vertical_space"></div>
				<div class="vertical_space"></div>
				<div v-if="inactive_slots_but_should_be_active.length > 0">
					<p class="bold">סלוטים שאמורים להיות אקטיביים מהשאפל אבל בפועל לא פעילים: ({{ inactive_slots_but_should_be_active.length }})</p>
					<div class="flex flex_wrap">
						<supplier-form-slot-item v-for="slot in inactive_slots_but_should_be_active" :_slot="slot" :is_retryable="true"></supplier-form-slot-item>
					</div>
				</div>
			</div>
			<div class="second_container">
				<div v-if="failed_retries_slots.length > 0">
					<p class="bold">סלוטים שנתקעו בתהליך: ({{ failed_retries_slots.length }})</p>
					<div class="flex flex_wrap">
						<supplier-form-slot-item v-for="slot in failed_retries_slots" :_slot="slot"></supplier-form-slot-item>
					</div>
				</div>
				<div class="vertical_space"></div>
				<div class="vertical_space"></div>
				<div v-if="active_slots_but_shouldnt_be_active.length > 0">
					<p class="bold">סלוטים שפעילים אבל לא אמורים להיות פעילים לפי השאפל: ({{ active_slots_but_shouldnt_be_active.length }})</p>
					<div class="flex flex_wrap">
						<supplier-form-slot-item v-for="slot in active_slots_but_shouldnt_be_active" :supplier_id="payload.supplier.id" :position_applicant_code="slot.position_applicant_code" :is_retryable="true">
						</supplier-form-slot-item>
					</div>
				</div>
				<div class="vertical_space"></div>
				<div class="vertical_space"></div>
				<div v-if="pending_positions.length > 0">
					<p class="bold">סלוטים שפנדינג בפועל: ({{ pending_positions.length }})</p>
					<div class="flex flex_wrap">
						<supplier-form-slot-item v-for="position in pending_positions" :supplier_id="payload.supplier.id" :position_applicant_code="position.position_applicant_code">
						</supplier-form-slot-item>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import { mapMutations } from 'vuex'
	import SupplierFormSlotItem from '@/components/SupplierFormSlotItem'
	export default {
		name: 'supplier-form',
		props: ['payload'],
		data(){
			return {
				local_slots: [],
				remote_slots: [],
				valid_slots: [],
				failed_retries_slots: [],
				active_slots_but_shouldnt_be_active: [],
				inactive_slots_but_should_be_active: [],
				pending_positions: []
			}
		},
		created(){
			this.init()
		},
		methods: {
			init(){
				let supplier_id = this.payload.supplier.id
				let requests = [
					this.$network_utils.getSlots({ supplier_id, statuses: 'active,pending_shuffle_out,pending_review' }), 
					this.$network_utils.getSupplierRemotePositions({ supplier_id, statuses: 'active' }), 
					this.$network_utils.getSupplierRemotePositions({ supplier_id, statuses: 'pending' }),
					this.$network_utils.getSlots({ supplier_id, statuses: 'expiring', updated_at_before: this.getTenMinutesAgoDate().toUTCString() })
				]
				this.$network_utils.caller.axios.all(requests).then(response => {
					this.local_slots = response[0]
					this.remote_slots = response[1]
					this.pending_positions = response[2]
					this.failed_retries_slots = response[3]
					this.setData()
				})
			},
			setData(){
				let remote_codes = [...this.remote_slots].map(remote_slot => this.getRemoteSlotCode(remote_slot))
				let local_codes = [...this.local_slots].map(local_slot => local_slot.position.position_applicant_code)
				console.log(`Remote Codes: `, remote_codes)
				console.log(`Local Codes: `, local_codes)
				remote_codes.forEach(remote_code => {
					if(local_codes.includes(remote_code)){
						this.valid_slots.push(this.getLocalSlotByCode(remote_code))
					}
				})
				remote_codes.forEach(remote_code => {
					if(!local_codes.includes(remote_code)){
						this.active_slots_but_shouldnt_be_active.push({ position_applicant_code: remote_code })
					}
				})
				local_codes.forEach(local_code => {
					if(!remote_codes.includes(local_code)){
						console.log("In active slot, but should be active: ", local_code)
						this.inactive_slots_but_should_be_active.push(this.getLocalSlotByCode(local_code))
					}
				})
			},
			getLocalSlotByCode(code){
				return this.local_slots.filter(local_slot => local_slot.position.position_applicant_code == code)[0]
			},
			getRemoteSlotCode(remote_slot){
				return remote_slot.position_applicant_code.split('_')[0]
			},
			getTenMinutesAgoDate(){
				let date = new Date()
				date.setMinutes(date.getMinutes() - 10)
				return date
			},
			...mapMutations('modals', ['createModal'])
		},
		components: {
			SupplierFormSlotItem
		}
	}
</script>
<style scoped>
	.server_slots {
		width: 50%;
		border-left: 1px solid rgba(0, 0, 0, 0.1);
		padding-left: 10px;
		margin-left: 10px;
	}
	.second_container{
		width: 50%;
		margin-right: 10px;
	}
	.slot {
		margin: 3px;
	}
</style>