<template>
	<div>
		<div v-if="form">
			<div>
				<a @click="boostPosition" class="button is-info">BOOST</a>
				<div class="vertical_space"></div>
				<div class="vertical_space"></div>
			</div>
			<div>
				<p>Priority</p>
		      	<input type="text" v-model="form.data.position.fields.priority.value">
			</div>
		</div>
		<div class="modal_footer">
			<a
				@click="submit()"
				:class="{ 'is-loading': is_loading }"
				class="button is-info"
				>Submit</a
			>
		</div>
	</div>
</template>
<script>
	import { mapMutations } from 'vuex'
	export default {
		name: 'position-form',
		props: ['payload'],
		data(){
			return {
				is_loading: false,
				form: undefined
			}
		},
		created(){
			this.$network_utils.getPosition({ env: this.payload.data.env, env_position_id: this.payload.data.env_position_id }).then(response => {
				this.setForm(response)
			})
		},
		methods: {
			setForm(form_data){
				this.form = new this.$form({
					position: {
					  fields: {
					    id: {
					      value: form_data.id,
					      has_error: false,
					      validations: []
					    },
					    env: {
					    	value: form_data.env,
					    	has_error: false,
					    	validations: []
					    },
					    env_position_id: {
					    	value: form_data.env_position_id,
					    	has_error: false,
					    	validations: []
					    },
					    priority: {
					    	value: form_data.priority,
					    	has_error: false,
					    	validations: []
					    }
					  },
					  request_details: {
					    method: this.payload.is_create ? 'post' : 'put',
					    url: '/positions/' + form_data.id,
					    service: 'shuffle'
					  },
					  is_used: true
					}
				})
			},
			boostPosition(){
		      	if (confirm('Are you sure you want to boost?')) {
			   		this.$network_utils.boostPosition({ env: this.payload.data.env, env_position_id: this.payload.data.env_position_id }).then(response => {
			   			this.$utils.emit('show_toast', { text: 'Boosted' })
			   		}).catch(() => {
			          this.$utils.emit('show_toast', { text: 'שגיאה' })
			        })
		   		}
			},
			submit(){
				let response = this.form.mySubmit()
				if (response === false) {
					return
				}
				this.is_loading = true
				response.promise.then((response) => {
					this.is_loading = false
					this.removeModal(this.payload)
				})
			},
			...mapMutations('modals', ['removeModal'])
		}
	}
</script>