import Caller from '@/classes/caller.js'

class NetworkUtils {
	
	constructor(){
		this.caller = new Caller()
	}

	setRouter (route) {
		this.route = route
	}

	getShowId () {
		return this.route.query.show_id == undefined ? '' : this.route.query.show_id
	}

	getPosition(params){
		return this.caller.request({ service: 'shuffle', method: 'get', url: '/positions/1', data: params })
	}

	getSlot(id){
		return this.caller.request({ service: 'shuffle', method: 'get', url: '/slots/' + id })
	}

	boostPosition(params){
		return this.caller.request({ service: 'shuffle', method: 'post', url: '/boost/', data: params })
	}

	getStargateAlerts(){
		return this.caller.request({ service: 'stargate', method: 'get', url: '/alerts/' })
	}

	getShuffleAlerts(){
		return this.caller.request({ service: 'shuffle', method: 'get', url: '/alerts/' })
	}

	getStargateStats(params){
		return this.caller.request({ service: 'stargate', method: 'get', url: '/stats/', data: params })
	}

	getShuffleStats(params){
		return this.caller.request({ service: 'shuffle', method: 'get', url: '/stats/', data: params })
	}

	getSupplierRemotePositions(params){
		return this.caller.request({ service: 'shuffle', method: 'get', url: '/get_supplier_remote_positions/', data: params })
	}

	getShuffleSuppliers(params){
		return this.caller.request({ service: 'shuffle', method: 'get', url: '/suppliers/' + this.getShowId(), data: params })
	}

	getStargateSuppliers(params){
		return this.caller.request({ service: 'stargate', method: 'get', url: '/suppliers/' + this.getShowId(), data: params })
	}

	getPositions(params){
		return this.caller.request({ service: 'shuffle', method: 'get', url: '/positions/' + this.getShowId(), data: params })
	}

	getTotalPriorityPositionsCount(params){
		return this.caller.request({ service: 'shuffle', method: 'get', url: '/get_total_priority_positions_count/', data: params })
	}

	getSlotsStats(params){
		return this.caller.request({ service: 'shuffle', method: 'get', url: '/get_slots_stats/', data: params })
	}

	getSlots(params){
		return this.caller.request({ service: 'shuffle', method: 'get', url: '/slots/', data: params })
	}

	updateSlot(params){
		return this.caller.request({ service: 'shuffle', method: 'put', url: '/slots/' + params.id, data: params })
	}

	deleteSlot(id){
		return this.caller.request({ service: 'shuffle', method: 'delete', url: `/slots/${id}`, data: id })
	}

	postEvent(params){
		return this.caller.request({ service: 'shuffle', method: 'post', url: '/events/', data: params })
	}

	login(params){
		return this.caller.request({ service: 'shuffle', method: 'post', url: '/authenticate/', data: params })
	}

}

export default NetworkUtils