import { getRandomString } from '@/classes/my_utils'
export const mutations = {
  createModal: (state, payload) => {
    payload.modal_id = getRandomString({ length: 8 })
    state.modals.push(payload)
  },
  updateModal: (state, updated_payload) => {
    state.modals = state.modals.map((modal_payload) => {
      if (modal_payload.modal_id == updated_payload.modal_id) {
        return updated_payload
      }
      return modal_payload
    })
  },
  removeModal: (state, payload, is_forced_remove) => {
    if (payload.is_fixed && !is_forced_remove) {
      return
    }
    state.modals = state.modals.filter((modal) => payload.modal_id !== modal.modal_id)
  },
  pop: (state, payload) => {
    state.modals.pop()
  },
  clearModals (state) {
    state.modals = []
  }
}

export const getters = {
  isModalOpen (state) {
    return (params) => getters.findModal(state)(params) != undefined
  },
  findModal (state) {
    return (params) => [...state.modals].reverse().find((modal) => modal[params.type] === params.value)
  },
  getModals (state) {
    return state.modals
  },
  getLastModal (state) {
    let modals = getters.getModals(state)
    return modals[modals.length - 1]
  }
}

export const modals = {
  namespaced: true,
  state: {
    modals: []
  },
  mutations,
  getters
}
