<template>
	<div>
		<div class="form_section">
			<p class="bold">{{ $utils.snakeCaseToSentence(payload.field_name) }}</p>
			<date-time v-if="payload.field_type == 'date_time'" :date="payload.value" v-model="value"/>
			<input v-if="payload.field_type == 'input'" type="text" v-model="value"/>
			<textarea v-if="payload.field_type == 'text_area'" v-model="value"/>
			<select v-if="payload.field_type == 'select'" :value="payload.value" v-model="value">
				<option v-for="(option, index) in payload.select_options" :value="option.value" :key="index">{{ option.text }}</option>
			</select>
		</div>
		<div class="modal_footer">
			<a
				@click="submit()"
				:class="{ 'is-loading': is_loading }"
				class="button is-info"
				>Submit</a
			>
		</div>
	</div>
</template>
<script>
	import { mapMutations } from 'vuex'
	import DateTime from '@/components/DateTime'
	export default {
		name: 'field-form',
		props: ['payload'],
		data(){
			return {
				is_loading: false,
				value: this.payload.value
			}
		},
		methods: {
		    submit () {
		    	this.is_loading = true
		    	let request_data = { id: this.payload.resource_id }
		    	request_data[this.payload.field_name] = this.value
		    	let resource_type = this.$utils.capitalizeFirstLetter(this.payload.resource_type)
		    	this.$network_utils[`update${resource_type}`](request_data).then(response => {
					this.$utils.emit('show_toast', { text: 'עודכן בהצלחה' })
		    		this.is_loading = false
		    		this.removeModal(this.payload)
		    		if(this.payload.resource_type == 'Offer' && this.value == 'paid'){
		    			this.openAddDatesSuggestion()
		    		}
		    	})
		    },
		    openAddDatesSuggestion(){
		    	this.createModal({
		    		designator: 'offer-add-dates-suggestion',
		    		component_name: 'AddOfferDatesSuggestion',
		    		offer_id: this.payload.resource_id,
		    		modal_title: 'Add Dates Suggestion'
		    	})
		    },
		    ...mapMutations('modals', ['createModal', 'removeModal'])
		},
		components: {
			DateTime
		}
	}
</script>