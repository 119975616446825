import { bus } from './bus.js'
import Settings from './settings.js'

export const isDevelopment = () => {
  return new Settings().get({ path: 'env' }) == 'development'
}

export const getInternalAuthToken = () => {
  let dev_token = 'eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjo0LCJleHAiOjE4NzIwNzA0NzZ9.mO_Nj3yQmQydactqv1wPqtEbB4cidO4QTEW-VTaXdbg'
  let token = isDevelopment() ? dev_token : window.localStorage.getItem('auth')
  return token
}

export const isLoggedIn = () => {
  return getInternalAuthToken() != undefined
}

export const emit = (event_name, data) => {
  bus.$emit(event_name, data)
}

export const getPropertyFromPathSafely = (params) => {
  try {
    return getPropertyFromPath(params)
  } catch {
    return undefined
  }
}

export const getNonUniqueValuesFromArray = (array) => {
  return array.filter((e, i, a) => a.indexOf(e) !== i)
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const snakeCaseToSentence = (s) =>
// https://gist.github.com/jacks0n/e0bfb71a48c64fbbd71e5c6e956b17d7
  s.match(/[a-z]+/gi)
    .map(function (word) {
      return word.charAt(0).toUpperCase() +  word.substr(1).toLowerCase()
    })
    .join(' ')

// https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
export const getRandomString = (params) => (Math.random() + 1).toString(36).substring(2, 2 + params.length)

export const getPropertyFromPath = (params) => {
  // https://stackoverflow.com/questions/359788/how-to-execute-a-javascript-function-when-i-have-its-name-as-a-string
  let context = params.context
  let path = params.path
  var namespaces = path.split('.')
  for (var i = 0; i < namespaces.length; i++) {
    context = context[namespaces[i]]
  }
  return context
}

export const getDomain = () => {
  let data = window.location.href
  var a = document.createElement('a')
  a.href = data
  return a.hostname
}

export const isNumeric = (n) =>{
  return !isNaN(parseFloat(n)) && isFinite(n)
}

// https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
export const formatSalary = (salary) => {
    return salary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}  

// https://stackoverflow.com/questions/5999118/how-can-i-add-or-update-a-query-string-parameter
export const updateQueryStringParameter = (url, key, value) => {
  var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i')
  var separator = url.indexOf('?') !== -1 ? '&' : '?'
  if (url.match(re)) {
    return url.replace(re, '$1' + key + '=' + value + '$2')
  } else {
    return url + separator + key + '=' + value
  }
}

export const updateQueryParams = (url, params) => {
  let new_url = url.toString()
  for (let param in params) {
    let value = params[param]
    if (value == undefined) {
      continue
    }
    new_url = updateQueryStringParameter(new_url, param, value)
  }
  return new_url
}

export const openInNewTab = (url) => {
  // https://stackoverflow.com/questions/4907843/open-a-url-in-a-new-tab-and-not-a-new-window-using-javascript
  var win = window.open(url, '_blank')
  if (win != undefined && win != null) {
    win.focus()
  }
}

export const getHebrewLetters = () => {
  return [
    'א',
    'ב',
    'ג',
    'ד',
    'ה',
    'ו',
    'ז',
    'ח',
    'ט',
    'י',
    'כ',
    'ל',
    'מ',
    'נ',
    'ס',
    'ע',
    'פ',
    'צ',
    'ק',
    'ר',
    'ש',
    'ת',
    'ן',
    'ם',
    'ך',
    'ף',
    'ץ'
  ]
}

export const getEnglishLetters = () => {
  return [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z'
  ]
}

export default {
  isLoggedIn,
  getInternalAuthToken,
  emit,
  getPropertyFromPathSafely,
  getRandomString,
  getPropertyFromPath,
  getDomain,
  isNumeric,
  updateQueryParams,
  updateQueryStringParameter,
  formatSalary,
  openInNewTab,
  capitalizeFirstLetter,
  snakeCaseToSentence,
  getHebrewLetters,
  getEnglishLetters,
  getNonUniqueValuesFromArray
}
