<template>
  <div>
    <modal class="custom_modal" v-for="(modal, index) in getModals" :payload="modal" :key="'modal-' + index" />
  </div>
</template>
<script>
import { mapMutations, mapGetters } from 'vuex'
import Modal from '@/components/Modal'
const ESCAPE = 27
const SHIFT = 16
export default {
  name: 'modals',
  computed: mapGetters('modals', ['getModals', 'getLastModal']),
  components: { Modal },
  watch: {
    $route () {
      this.clearModals()
    }
  },
  mounted () {
    this.addKeyboardListener()
  },
  destroyed () {
    this.removeKeyboardListener()
  },
  methods: {
    addKeyboardListener () {
      window.addEventListener('keydown', this.handleKeyListener)
    },
    removeKeyboardListener () {
      window.removeEventListener('keydown', this.handleKeyListener)
    },
    handleKeyListener (event) {
      let last_modal = this.getLastModal
      if (last_modal == undefined) {
        return
      }
      if (event.keyCode === ESCAPE && !last_modal.ignore_escape_button) {
        this.pop()
      }
      if (event.keyCode === SHIFT) {
        last_modal.is_fixed = true
        this.update(last_modal)
      }
    },
    ...mapMutations('modals', [
      'pop', 'update', 'clearModals'
    ])
  }
}
</script>
