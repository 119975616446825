import axios from 'axios'
import Settings from '@/classes/settings.js'
import { updateQueryParams, getInternalAuthToken } from '@/classes/my_utils.js'

class Caller {
  /**
   * Create a new Caller instance.
   */
  constructor () {
    this.axios = axios
    this.settings = new Settings()
  }

  getBaseUrl(service){
    if(service == 'stargate'){
      return this.settings.get({ path: 'stargate_api_url' })
    }
    if(service == 'shuffle'){
      return this.settings.get({ path: 'shuffle_api_url' })
    }
  }

  request (params) {
  	params.url = this.getBaseUrl(params.service) + params.url
    params.url = params.method.toLowerCase() == 'get' ? updateQueryParams(params.url, params.data) : params.url
    return new Promise((resolve, reject) => {
      axios
        .request({
          method: params.method,
          responseType: params.data ? params.data.responseType : undefined,
          url: params.url,
          headers: { Authorization: getInternalAuthToken() },
          data: params.data
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

}

export default Caller
