import Caller from '@/classes/caller.js'
import Validator from '@/classes/validator.js'

class Form {
  /**
   * Create a new Form instance.
   *
   * @param {object} data
   */
  constructor (data) {
    this.originalData = JSON.parse(JSON.stringify(data))
    this.data = data
    this.caller = new Caller()
    this.validator = new Validator()
  }

  validate () {
    let passed = true
    for (let form_name in this.data) {
      if (!this.data[form_name].is_used) {
        continue
      }
      if (!this.validateForm(this.data[form_name])) {
        console.log('validation failed, form_name: ' + form_name)
        passed = false
      }
    }
    return passed
  }

  validateForm (form) {
    let passed = true
    for (let key in form.fields) {
      if (!this.validateSingleField(form.fields[key])) {
        passed = false
        form.fields[key].has_error = true
      }
    }
    return passed
  }

  validateSingleField (field) {
    let passed = true
    for (var i = field.validations.length - 1; i >= 0; i--) {
      if (!this.validator['validate' + field.validations[i]](field)) {
        passed = false
      }
    }
    return passed
  }

  callServer () {
    console.log('calling server')
    let requests = []
    let request_names = []
    for (let form_name in this.data) {
      let form = this.data[form_name]
      if (!form.is_used) {
        continue
      }
      console.log(`making a call for ${form_name}`)
      requests.push(this.sendForm(form))
      request_names.push(form_name)
    }
    return { promise: this.caller.axios.all(requests), request_names: request_names }
  }

  sendForm (form) {
    let data = this.getData(form)
    return this.caller.request({ service: form.request_details.service, method: form.request_details.method, url: form.request_details.url, data })
  }

  getData (form) {
    let data = {}
    if (typeof form.request_details.extra_data === 'object') {
      data = form.request_details.extra_data
    }
    for (let key in form.fields) {
      data[key] = form.fields[key].value
    }
    return data
  }

  mySubmit () {
    if (this.validate()) {
      console.log('passed validation')
      return this.callServer()
    } else {
      console.log('failed validation')
      return false
    }
  }
}

export default Form
