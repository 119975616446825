<template>
	<div>
		<div class="info_section">
			<p class="bold">Publish To</p>
			<div v-if="is_loading_suppliers" class="flex">
				<div class="loading"></div>
			</div>
			<select @change="getActiveRemoteSlots" v-model="supplier_id">
				<option	v-for="supplier in suppliers" :value="supplier.id">
					{{ supplier.outsource_class }} - {{ supplier.username }} ({{ supplier.id }}) <span v-if="unfull_booked_suppliers_ids.includes(supplier.id)"> - UNFULL</span>
				</option>
			</select>
		</div>
		<div v-if="supplier_id != undefined" class="info_section">
			<div v-if="is_loading_active_remote_slots" class="loading"></div>
			<div v-else>
				<p><b>SLOTS SIZE: </b> {{ getSelectedSupplier.slots_size }}</p>
				<p><b>ACTIVE SLOTS: </b> {{ active_remote_slots.length }}</p>
			</div>
		</div>
		<div style="margin-top: 20px;">
			<p class="bold">Position Applicant Code</p>
			<div class="flex flex_align_center">
				<input v-model="position_applicant_code" type="text">
				<a @click="searchPosition" class="button is-info search_button">Search</a>
			</div>
		</div>
		<div class="info">
			<div v-if="is_search_position" class="flex flex_align_center">
				<div class="loading"></div>
			</div>
			<div v-if="position">
				<div class="info_section">
					<p><b>כותרת:</b> {{ position.title }}</p>
				</div>
				<div class="info_section">
					<p><b>תיאור:</b></p>
					<p v-html="position.description"></p>
				</div>
				<div class="info_section">
					<p><b>דרישות:</b></p>
					<p v-html="position.requirements"></p>
				</div>	
			</div>			
		</div>
		<div class="modal_footer">
			<a 	@click="approve" class="button is-info" 
				:class="{ faded: position == undefined || supplier_id == undefined || is_approved, 'is-loading': is_approving }">
				Approve
			</a>
		</div>
	</div>
</template>
<script>
	import { mapMutations } from 'vuex'
	export default {
		name: 'publish-position-form',
		props: {
			payload: {
				type: Object
			}
		},
		data(){
			return {
				suppliers: [],
				supplier_id: this.payload.supplier_id,
				is_loading_suppliers: true,
				position_applicant_code: this.payload.position != undefined ? this.payload.position.position_applicant_code : '',
				is_approving: false,
				is_approved: false,
				is_search_position: false,
				position: this.payload.position,
				is_loading_active_remote_slots: false,
				active_remote_slots: [],
				unfull_booked_suppliers_ids: []
			}
		},
		computed: {
			getSelectedSupplier(){
				if(this.supplier_id == undefined){
					return
				}
				return this.suppliers.filter(supplier => supplier.id == this.supplier_id)[0]
			}
		},
		created(){
			let requests = [
				this.$network_utils.getShuffleSuppliers(),
				this.$network_utils.getShuffleSuppliers({ is_fully_booked: false })
			]
			Promise.all(requests).then(response => {
				this.suppliers = response[0]
				this.unfull_booked_suppliers_ids = response[1].map(supplier => supplier.id)
				this.is_loading_suppliers = false
			})
			if(this.supplier_id != undefined){
				this.getActiveRemoteSlots()
			}
		},
		methods: {
			searchPosition(){
				if(this.position_applicant_code.length == 0){
					this.$utils.emit('show_toast', { text: 'Position Applicant Code is Empty' })
					return
				}
				this.is_search_position = true
				this.$network_utils.getPositions({ position_applicant_code: this.position_applicant_code }).then(response => {
					this.position = response[0]
					this.is_search_position = false
				})
			},
			approve(){
				this.is_approving = true
				this.$network_utils.postEvent({ 
					event: 'PublishPosition', 
					position_id: this.position.id, 
					supplier_id: this.supplier_id
				}).then(() => {
					this.$utils.emit('show_toast', { text: 'Published Succesfully' })
					this.is_approving = false
					this.is_approved = true
					this.removeModal(this.payload)
				})
			},
			getActiveRemoteSlots(){
				this.is_loading_active_remote_slots = true
				this.$network_utils.getSupplierRemotePositions({ supplier_id: this.supplier_id, statuses: 'active' }).then(response => {
					this.active_remote_slots = response
					this.is_loading_active_remote_slots = false
				})
			},
			...mapMutations('modals', ['removeModal'])
		}
	}
</script>
<style scoped>
	.search_button {
		margin-right: 10px;
	}
	.info {
		margin-top: 10px;
	}
	.info_section {
		margin-bottom: 20px;
	}
	.faded {
		opacity: 0.5;
		pointer-events: none;
	}
</style>