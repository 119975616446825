export const mutations = {
  setCursorX (state, cursor_x) {
    state.cursor_x = cursor_x
  }
}

export const getters = {
  getCursorX (state) {
    return state.cursor_x
  }
}

export const general = {
  namespaced: true,
  state: {
    cursor_x: 0
  },
  mutations,
  getters
}
