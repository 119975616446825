<template>
  <div :class="payload.designator">
    <div class="pop_up_container" :style="{ left: modal_x, top: modal_y, width: modal_width + 'px' }">
      <draggable @x_coordinate="setModalX" @y_coordinate="setModalY">
        <div class="modal_title flex flex_align_center flex_justify_between">
          <p class="margin_0">{{ payload.modal_title }}</p>
          <img @click="closeModal" class="modal-close-btn btn" src="/white_x.svg" width="12px">
        </div>
      </draggable>
      <component class="modal_form modal_content" dir="rtl" :is="payload.component_name" :payload="payload"/>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import Draggable from '@/components/Draggable'
import FieldForm from '@/forms/FieldForm.vue'
import PositionForm from '@/forms/PositionForm.vue'
import SupplierForm from '@/forms/SupplierForm.vue'
import SlotForm from '@/forms/SlotForm.vue'
import SlotInfoForm from '@/forms/SlotInfoForm.vue'
import PublishPositionForm from '@/forms/PublishPositionForm.vue'
export default {
  props: ['payload'],
  name: 'modal',
  data () {
    return {
      is_loading: false,
      modal_x: 0,
      modal_y: 'calc(50% - 35px)'
    }
  },
  created () {
    this.initModalX()
  },
  watch: {
    payload () {
      this.initModalX()
    }
  },
  computed: {
    modal_width () {
      return this.payload.modal_width == undefined ? 750 : this.payload.modal_width
    },
    ...mapGetters('general', ['getCursorX'])
  },
  methods: {
    initModalX () {
      if (this.payload.is_center_modal) {
        this.modal_x = (window.innerWidth / 2) - (this.modal_width / 2) + 'px'
        return
      }
      if (this.payload.override_modal_x != undefined) {
        this.modal_x = this.payload.override_modal_x + 'px'
        return
      }
      this.modal_x = this.calculateModalXCoordinate() + 'px'
    },
    setModalX (modal_x) {
      this.modal_x = modal_x + 'px'
    },
    setModalY (modal_y) {
      this.modal_y = modal_y + 'px'
    },
    calculateModalXCoordinate () {
      let cursor_x = this.getCursorX
      let modal_width = this.modal_width
      let window_width = window.innerWidth
      let offset = 120
      if (cursor_x + (modal_width + offset) >= window_width) {
        return (cursor_x - this.modal_width - offset)
      }
      return (cursor_x + offset)
    },
    closeModal () {
      let payload = this.payload
      if (payload == undefined) {
        return
      }
      payload.is_fixed = false
      this.removeModal(payload)
    },
    ...mapMutations('modals', ['removeModal'])
  },
  components: {
    Draggable,
    FieldForm,
    PositionForm,
    SupplierForm,
    SlotForm,
    SlotInfoForm,
    PublishPositionForm
  }
}
</script>
<style scoped>
.modal_content {
  text-align: right;
  overflow-y: auto;
  padding: 30px;
  max-height: 80vh;
}
.center_modal {
  position: absolute;
  left: calc(var(--left-column-width) * -1);
  padding-right: 60px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-background {
  position: fixed;
  z-index: 100000;
}
.pop_up_container {
  animation: 240ms cubic-bezier(0.25, 0.1, 0.25, 1) 0s 1 normal forwards running
    pop-up-animation;
  position: fixed;
  border-radius: 10px;
  z-index: 1000000;
  max-width: 95vw;
  background: white;
  -webkit-box-shadow: 0px 0px 67px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 67px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 67px -3px rgba(0, 0, 0, 0.75);
}
@keyframes pop-up-animation {
  0% {
    opacity: 0;
  }
  25% {
    transform: translateY(-50%) scale(0.9);
  }
  100% {
    opacity: 1;
    transform: translateY(-50%) scale(1);
  }
}
.modal_title {
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
  transition: 0.3s;
  cursor: move;
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 60px;
  padding: 10px 20px;
  font-size: 24px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: white;
  background-color: var(--color-header);
}
.modal_title:active{
  transition: 0.1s;
  color: black;
  background-color: #f2f2f2;
}
.modal_title p{
  pointer-events: none;
}
</style>
