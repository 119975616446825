import moment from 'moment/moment.js'
class TimeHelper {
  constructor () {
    this.moment = moment
    require('moment/locale/he')
  }

  getDate (date_string) {
    if (date_string.length > 0) {
      return new Date(date_string)
    }
    return new Date()
  }

  getTimeInput (date_string) {
    let date = new Date(date_string)
    let minutes = this.makeTwoChars(date.getMinutes())
    let hours = this.makeTwoChars(date.getHours())
    return hours + ':' + minutes
  }

  getDateInput (date_string) {
    let date = new Date(date_string)
    let month = this.makeTwoChars(date.getMonth() + 1)
    let day = this.makeTwoChars(date.getDate())
    return date.getFullYear() + '-' + month + '-' + day
  }

  makeTwoChars (my_object) {
    let as_string = my_object.toString()
    if (as_string.length == 1) {
      return '0' + as_string
    }
    return as_string
  }

  getDateInMoreDays (days) {
    let current_date = this.getCurrentDate()
    return new Date(
      current_date.getFullYear(),
      current_date.getMonth(),
      current_date.getDate() + days
    )
  }

  getToday () {
    let date = this.getCurrentDate()
    date.setHours(0, 0, 0, 0)
    return date
  }

  getYesterday () {
    let date = this.getCurrentDate()
    date.setDate(date.getDate() - 1)
    return date
  }

  getLastWeek () {
    let date = this.getCurrentDate()
    date.setDate(date.getDate() - 7)
    return date
  }

  getLastTwoWeeks(){
    let date = this.getCurrentDate()
    date.setDate(date.getDate() - 14)
    return date
  }

  getLastMonth(){
    let date = this.getCurrentDate()
    date.setMonth(date.getMonth() - 1)
    return date 
  }

  getTomorrow () {
    let date = this.getCurrentDate()
    date.setDate(date.getDate() + 1)
    return date
  }

  getCurrentDate () {
    return new Date()
  }

  setDate (value) {
    var date = new Date(value)
    if (this.validDate(date)) {
      return date
    }
    return false
  }

  setTime (date, value) {
    let next_contact_time = value.split(':')
    if (next_contact_time.length > 1) {
      date.setHours(next_contact_time[0] * 1)
      date.setMinutes(next_contact_time[1] * 1)
      return date
    }
    date.setHours(0, 0, 0)
    return date
  }

  validDate (d) {
    // https://stackoverflow.com/questions/1353684/detecting-an-invalid-date-date-instance-in-javascript
    if (Object.prototype.toString.call(d) === '[object Date]') {
      // it is a date object
      if (isNaN(d.getTime())) {
        // d.valueOf() could also work
        return false
      }
      return true
    }
    return false
  }

  makeTimeReadable (time) {
    if(time == undefined){
      return null
    }
    return moment.utc(new Date(time)).format("L");
  }

  getDayOfWeekInHebrew () {
    return ['ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי', 'שבת']
  }

  getMonthsNames(){
    return ['ינואר', 'פברואר', 'מרץ', 'אפריל', 'מאי', 'יוני', 'יולי', 'אוגוסט', 'ספטמבר', 'אוקטובר', 'נובמבר', 'דצמבר']
  }

  getReadableMonth(month){
    return this.getMonthsNames()[month]
  }

  isSameDate (date1, date2) {
    return date1.getDate() == date2.getDate() && date1.getMonth() == date2.getMonth()
  }

}

export default TimeHelper
