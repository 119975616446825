  import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'dashboard',
    // route level code-splitting
    // this generates a separate chunk (home.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '@/pages/Dashboard.vue')
  },
  {
    path: '/positions',
    name: 'positions',
    // route level code-splitting
    // this generates a separate chunk (positions.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "positions" */ '@/pages/Positions.vue')
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '@/pages/Login.vue')
  },
  {
    path: '/suppliers',
    name: 'suppliers',
    // route level code-splitting
    // this generates a separate chunk (suppliers.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "suppliers" */ '@/pages/Suppliers.vue')
  },
  {
    path: '/slots',
    name: 'slots',
    // route level code-splitting
    // this generates a separate chunk (slots.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "slots" */ '@/pages/Slots.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
