<template>
  <div @touchstart="dragStart" @touchend="dragEnd" @touchmove="drag" @mousedown="dragStart" @mouseup="dragEnd" @mousemove="drag" ref="drag_target">
    <div>
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'draggable',
  data () {
    return {
      is_dragging: false,
      xOffset: 0,
      yOffset: 0,
      currentX: 0,
      currentY: 0,
      initialX: 0,
      initialY: 0
    }
  },
  beforeDestroy () {
    window.removeEventListener('mousemove', this.drag)
    window.removeEventListener('mouseup', this.dragEnd)
  },
  mounted () {
    this.xOffset = this.$refs.drag_target.getBoundingClientRect().left
    this.yOffset = this.$refs.drag_target.getBoundingClientRect().top
    window.addEventListener('mousemove', this.drag)
    window.addEventListener('mouseup', this.dragEnd)
  },
  methods: {
    dragStart (event) {
      this.is_dragging = true
      if (event.type === 'touchstart') {
        this.initialX = event.touches[0].clientX - this.xOffset
        this.initialY = event.touches[0].clientY - this.yOffset
        return
      }
      this.initialX = event.clientX - this.xOffset
      this.initialY = event.clientY - this.yOffset
    },
    dragEnd (event) {
      this.initialX = this.currentX
      this.initialY = this.currentY
      this.is_dragging = false
    },
    drag (event) {
      if (!this.is_dragging) {
        return
      }
      if (event.type === 'touchmove') {
        event.preventDefault()
        this.currentX = event.touches[0].clientX - this.initialX
        this.currentY = event.touches[0].clientY - this.initialY
      } else {
        this.currentX = event.clientX - this.initialX
        this.currentY = event.clientY - this.initialY
      }
      this.xOffset = this.currentX
      this.yOffset = this.currentY
      this.$emit('x_coordinate', this.currentX)
      this.$emit('y_coordinate', this.currentY)
    }
  }
}
</script>
