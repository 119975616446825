<template>
  <div dir="rtl">
    <input dir="ltr" @input="changed" v-model="date_string" placeholder="20/03/2017" type="date" :class="{ outlined: true }" />
  </div>
</template>
<script>
import TimeHelper from '@/classes/time_helper.js'
export default {
  name: 'date-time',
  props: ['date', 'outlined'],
  data () {
    let time_helper = new TimeHelper()
    return {
      time_helper: time_helper,
      date_string: '',
    }
  },
  mounted () {
    this.init()
  },
  watch: {
    date () {
      this.init()
    }
  },
  methods: {
    init () {
      if (new Date(this.date).getFullYear() < 1000) {
        return
      }
      this.date_string = this.date == undefined ? '' : this.time_helper.getDateInput(this.date)
    },
    changed () {
      this.$emit('input', this.getDateString())
    },
    getDateString () {
      let date = this.time_helper.setDate(this.date_string)
      if (!date) {
        return null
      }
      return date.toUTCString()
    }
  }
}
</script>
