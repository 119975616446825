/* eslint-disable */
import { isNumeric } from './my_utils.js'
class Validator {

  validateEmail(field) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(field.value);
  }

  validateExists(field) {
    return ((field.value + '').trim().length > 0) && (field.value != null)
  }

  validatePositive(field) {
    return Number(field.value) > 0;
  }

  validateNumber(field) {
    if(field.value == undefined){
      return true
    }
    return isNumeric(field.value)
  }

  hasBreakLine(string){
    return (string.match(/\n/g) || []).length > 0
  }

  multipleEmailsValidationSanity (emails) {
    emails = emails.split(',')
    let unvalidated_emails = []
    for (let i = 0; i < emails.length; i++) {
      let email = emails[i]
      if(this.hasBreakLine(email)){
        alert('יש אנטרים במייל, אנא מחק/י אותם')
        return false
      }
      if (!this.validateEmail({ value: email.trim() })) {
        unvalidated_emails.push(email)
      }
    }
    if (unvalidated_emails.length <= 0) {
      return true
    }
    alert('האימליים הבאים לא תקינים:\n ' + unvalidated_emails.join(','))
    return false
  }

}

export default Validator;
