/* eslint-disable */
class CustomPrototypes {
  setPrototypes () {
    Date.prototype.atMidnight = function () {
      this.setHours(0, 0, 0, 0)
      return this
    }
    Date.prototype.atEndOfTheDay = function () {
      this.setHours(23, 59, 0, 0)
      return this
    }
  }
}

export default CustomPrototypes
