import sanitizeHTML from 'sanitize-html'

export const getAllowedTags = () => {
  let allowedTags = sanitizeHTML.defaults.allowedTags.concat()
  let tagsBlacklist = ['iframe', 'a']
  allowedTags.push('button')
  allowedTags.push('img')
  allowedTags.push('ul')
  allowedTags.push('span')
  allowedTags.push('ol')
  allowedTags.push('li')
  allowedTags.push('table')
  allowedTags.push('td')
  allowedTags.push('th')
  allowedTags.push('thead')
  allowedTags.push('tr')
  allowedTags.push('u')
  allowedTags.push('h1')
  allowedTags.push('h2')
  allowedTags.push('h3')
  allowedTags.push('select')
  allowedTags.push('option')
  allowedTags.push('input')
  allowedTags.push('textarea')
  allowedTags.push('label')
  for (let i = 0; i < allowedTags.length; i++) {
    if (tagsBlacklist.includes(allowedTags[i])) {
      allowedTags.splice(i, 1)
    }
  }
  return allowedTags
}

export const getAllowedAttributes = () => {
  let allowedAttributes = sanitizeHTML.defaults.allowedAttributes
  allowedAttributes['*'] = ['width', 'height', 'hidden', 'placeholder', 'rows', 'cols', 'class', 'id', 'type', 'value', 'resource', 'text', 'checked', 'name', 'max', 'min', 'selected']
  return allowedAttributes
}

export const sanitize = (html) => {
  return sanitizeHTML(html, {
    allowedAttributes: getAllowedAttributes(),
    allowedTags: getAllowedTags()
  })
}

export default {
  getAllowedTags,
  getAllowedAttributes,
  sanitize
}
